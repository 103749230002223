import React, { Component } from 'react'
import { Image, Col } from 'react-bootstrap'

export default class componentName extends Component {
    render() {
        return (
            <React.Fragment>
                <Col sm={12} md={6} lg={4} className="text-center">

                    <div className="card-services">
                        <div data-aos="zoom-in" data-aos-anchor-placement="top-bottom">
                            <Image fluid src={this.props.srcc} className="icon-card-services" alt="invictus" />
                            <h2 className="title-card-services">{this.props.children}</h2>
                        </div>
                    </div>

                </Col>
            </React.Fragment>
        )
    }
}
