import React from 'react'
import { Container, Row, Col, Form, Button, Image } from 'react-bootstrap'
import mail from '../assets/images/mail icon.svg'

const Contact = () => {

    const handleClick = () => {

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'popunjenaForma',
            'formType': 'Popunjena forma',
        });

        // console.log('?', "Popunjena forma")
    }

    return (
        <React.Fragment>
            <div className="contact" id="ContactUs">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                                <h2 className="title-contact">Contact Us</h2>
                            </div>
                            <div className="card-contact">
                                <Row>
                                    <Col sm={12} md={12} lg={6} className="lijevo">

                                        <Form
                                            action="https://formspree.io/f/xqkoalrp"
                                            method="POST"
                                            className="form-contact"
                                        >
                                            <p className="text-contact">Get in touch using contact form below. We will try to reach you as soon as possible.</p>
                                            <Form.Control className="input-contact" type="text" placeholder="Name" name="Name" required />
                                            <Form.Control className="input-contact" type="email" placeholder="E-mail" name="E-mail" required />
                                            <Form.Control className="textarea-contact" rows="5" as="textarea" type="password" placeholder="Message..." name="Message" required />
                                            <div className="position-btn-contact">
                                                <Button
                                                    onClick={handleClick}
                                                    type="submit"
                                                    className="btn-3"
                                                >
                                                    Send Message
                                                </Button>
                                            </div>
                                        </Form>
                                    </Col>
                                    <Col sm={12} md={12} lg={6} className="desno">
                                        <Image className="mail-contact" src={mail} alt="mail" />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Contact
