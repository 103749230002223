import React, { Component } from 'react';
import { Navbar, Button, Container, Image } from 'react-bootstrap';
import logo from '../assets/images/invictus.svg'
import AnchorLink from 'react-anchor-link-smooth-scroll'

export default class componentName extends Component {
    render() {
        return (
            <Navbar fixed="top" className="navigation">
                <Container>

                    <Navbar.Brand className="mr-auto">

                        <Image src={logo} className="logo" alt="invictus"></Image>
                    </Navbar.Brand>
                    <div className="mr-auto ml-auto links">
                        <AnchorLink offset='60' href='#OurServices'>Our Services</AnchorLink>
                        <AnchorLink offset='60' href='#Testimonials'>Testimonials</AnchorLink>
                        <AnchorLink offset='60' href='#OurWorks'>Our Works</AnchorLink>
                        <AnchorLink offset='60' href='#Prices'>Prices</AnchorLink>
                        <AnchorLink offset='20' href='#ContactUs'>Contact Us</AnchorLink>
                    </div>
                    <div className="ml-auto">
                        <AnchorLink offset='20' href='#ContactUs'>
                            <Button className="contact-us">
                                Contact Us
                            </Button>
                        </AnchorLink>
                    </div>
                </Container>

            </Navbar>
        )
    }
}
