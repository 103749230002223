import React, { Component } from 'react'
import '../assets/css/home.css';
import { Container, Button } from 'react-bootstrap';

export default class componentName extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="intro">
                    <Container>
                        <h1 className="title">Give Your Walls Life!</h1>
                        <p className="text">Residential, commercial and industrial painting services in Melbourne with warranty.
                        Our team of experts will meet your expectations and provide you excellent results with our painting options.
                        </p>
                        <p className="text">WE WILL BEAT ANY COMPETITORS WRITTEN QUOTE BY A MINIMUM OF 10%.</p>
                        <a className="reset" href="tel:0400 071 127">
                            <Button className="btn-1">Contact Us 0400 071 127</Button>
                        </a>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}
