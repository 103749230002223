import React, { Component } from 'react'
import { Container, Row, Col, Image, Button } from 'react-bootstrap'
import Slider from "react-slick";
import img1 from '../assets/images/slide/1.jpg'
import img2 from '../assets/images/slide/2.jpg'
import img3 from '../assets/images/slide/3.jpg'
import img4 from '../assets/images/slide/4.jpg'
import img5 from '../assets/images/slide/5.jpg'
import img6 from '../assets/images/slide/6.jpg'
import AnchorLink from 'react-anchor-link-smooth-scroll'

export default class componentName extends Component {
    render() {
        const settings = {
            dots: true,
            fade: true,
            arrows: false,
            infinite: true,
            autoplay: true,
            speed: 800,
            autoplaySpeed: 4000,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (
            <React.Fragment>
                <div className="work" id="OurWorks">
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                                    <h2 className="title-work">
                                        Our Works
                                    </h2>
                                </div>
                            </Col>
                        </Row>
                        <Row className="row-invert">
                            <Col lg={6} className="col-slider">
                                <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                                    <p className="text-work">
                                        Our work is focused on providing you with an outstanding experience, whether that is residential, commercial, or industrial painting projects. Through various painting options, we ensure client satisfaction and meet your flexible needs. We use nothing but top-quality paints, stains, and coatings. The promise of quality, care, and certainty is what defines us. Our team of experienced exterior and interior painting professionals will deliver you high-quality results.

                                    </p>
                                    <p className="text-work-2">
                                        Finding the right company and team to paint your home or building can be challenging. With Invictus Painters, you can be sure that we will treat your facility with the care it deserves. Get acquainted with our recent painting projects and see why we are the best choice for you!
                                    </p>
                                </div>
                                <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                                    <AnchorLink offset='20' href='#ContactUs'>
                                        <Button className="btn-4">You like our works? Contact us here.</Button>
                                    </AnchorLink>
                                </div>
                            </Col>

                            <Col lg={6}>
                                <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                                    <Slider {...settings}>
                                        <Image fluid className="image-work" src={img1} />
                                        <Image fluid className="image-work" src={img2} />
                                        <Image fluid className="image-work" src={img3} />
                                        <Image fluid className="image-work" src={img4} />
                                        <Image fluid className="image-work" src={img5} />
                                        <Image fluid className="image-work" src={img6} />
                                    </Slider>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}
