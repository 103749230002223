import React, { Component } from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import favicon from '../assets/images/favicon.png'

export default class componentName extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="about">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                                    <h2 className="title-about">About Us</h2>
                                </div>
                                <div className="card-about">
                                    <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                                        <Row>
                                            <Col lg={4} md={12} sm={12} className="img-col-about">
                                                <Image className="img-about" fluid src={favicon} alt="invictus" />
                                            </Col>
                                            <Col lg={8} md={12} sm={12} className="img-col-about">
                                                <p className="text-about">
                                                    If you're planning your next residential or commercial makeover, contact the pros at Invictus Painters. We have a very experienced and reliable team of professionals. We offer comprehensive residential, commercial and industrial painting services in Melbourne, including internal and external. Our company is fully licensed and insured. We thoroughly prepare your space before each painting job to ensure that your furniture and floors are fully protected.
                                                </p>
                                                <p className="text-about">
                                                    Our professionals carefully prime each space before applying two coats of paint. Also, we won't make a mess and leave you to clean up; we will restore your space exactly how you like it. No exceptions. We use high-quality Environmentally-Friendly paint or the paint of your choice. Also, we background check and pre-screen all of our professionals so you can rest easy feeling confident about the people in your space. If you're searching for quality painters, call the professionals at Invictus Painters today to receive a free estimate.
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}
