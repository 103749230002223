import React, { Component } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import CardServices from '../components/CardServices'
import factory from '../assets/images/factory.svg'
import paint from '../assets/images/paint.svg'
import home from '../assets/images/home.svg'

export default class componentName extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="services" id="OurServices">
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                                    <h2 className="title-services">Our Services</h2>
                                    <p className="text-services">
                                        We offer a wide range of painting options handled by professionals. Quality, high speed, and precision are principles we are led by.
                                    </p>
                                </div>
                            </Col>
                            <CardServices srcc={factory}>
                                Industrial painting
                            </CardServices>
                            <CardServices srcc={paint}>
                                Commercial painting
                            </CardServices>
                            <CardServices srcc={home}>
                                Residential 
                                PAINTING
                            </CardServices>
                            <Col sm={12} className="text-center">
                                <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                                    <div className="btn-position-services">
                                        <a className="reset" href="tel:0400 071 127">
                                            <Button className="btn-1">
                                                Contact Us 0400 071 127
                                            </Button>
                                        </a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}
