import React, { Component } from 'react'
import { Container, Row, Col, Image, Button } from 'react-bootstrap'
import logoFooter from '../assets/images/logo-footer.svg'
export default class componentName extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="footer">
                    <Container>
                        <Row>
                            <Col sm={12} className="text-center">
                                <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                                    <Image fluid className="logo-footer" src={logoFooter} />
                                </div>
                            </Col>
                            <Col sm={12} className="text-center">
                                <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                                    <h2 className="quote">"The essence of interior design will always be about people and how they live. It is about the realities of what makes for an attractive, civilized, meaningful environment, not about fashion or what's in or what's out. This is not an easy job."
                                        <br /><br /> Nate Berkus
                                    </h2>
                                </div>
                            </Col>
                            <Col sm={12} className="text-center">
                                <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                                    <a className="reset" href="tel:0400 071 127">
                                        <Button className="btn-2">
                                            Contact Us 0400 071 127
                                        </Button>
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="copywrite">
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <p className="text-copywrite">© 2020 | Invictus Painting</p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}
