import React, { Component } from 'react'
import { Container, Col, Row, Image } from 'react-bootstrap'
import wattyl from '../assets/images/logo-colors/wattyl logo.svg'
import dulux from '../assets/images/logo-colors/dulux logo.svg'
import haymes from '../assets/images/logo-colors/haymes logo.svg'
import taubmans from '../assets/images/logo-colors/taubmans logo.svg'

export default class componentName extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="prices" id="Prices">
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                                    <h2 className="title-prices">Prices</h2>
                                </div>
                            </Col>
                            <Col sm={12} md={6} lg={4}>
                                <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                                    <div className="card-prices">
                                        <h3 className="title-card-prices">New build homes</h3>
                                        <p className="text-card-prices">All wall surfaces are calculated for 2,4 m high ceilings. All prices include labor and paint.</p>
                                        {/* <div className="circle-card-prices">
                                            <div className="m2-card-prices">
                                                42 <span>m²</span>
                                            </div>
                                        </div> */}
                                        <div className="footer-card-prices">
                                            <h3 className="from-card-prices">Cost per m²</h3>
                                            <h3 className="price-footer-card">$35 - $100</h3>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            {/* <Col sm={12} md={6} lg={4}>
                                <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                                    <div className="card-prices">
                                        <h3 className="title-card-prices">Repainting</h3>
                                        <p className="text-card-prices">All Wall Surfaces are calculated for 2,4 m high ceilings. All prices include labor and paint.</p>
                                        <div className="circle-card-prices">
                                            <div className="m2-card-prices">
                                                42 <span>m²</span>
                                            </div>
                                        </div>
                                        <div className="footer-card-prices">
                                            <h3 className="from-card-prices">From</h3>
                                            <h3 className="price-footer-card">$800</h3>
                                        </div>
                                    </div>
                                </div>
                            </Col> */}

                            <Col sm={12} md={6} lg={4}>
                                <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                                    <div className="card-prices">
                                        <h3 className="title-card-prices">Repainting</h3>
                                        <p className="text-card-prices">All wall surfaces are calculated for 2,4 m high ceilings. All prices include labor and paint.</p>
                                        <div className="footer-card-prices">
                                            <h3 className="from-card-prices">Cost per m²</h3>
                                            <h3 className="price-footer-card">$20 - $100</h3>
                                        </div>
                                    </div>
                                </div>
                            </Col>


                            <Col sm={12} md={6} lg={4}>
                                <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                                    <div className="card-prices">
                                        <h3 className="title-card-prices">Prodigious painting jobs</h3>
                                        <p className="text-card-prices">All wall surfaces are calculated for 2,4 m high ceilings. All prices include labor and paint.</p>
                                        <div className="footer-card-prices">
                                            <h3 className="from-card-prices">Cost per m²</h3>
                                            <h3 className="price-footer-card">$100 - $300</h3>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                        </Row>
                        <Row className="form-inline row-logo">
                            <Col xs={6} sm={6} md={6} lg={3}>
                                <Image fluid src={wattyl} alt="invictus" />
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={3}>
                                <Image fluid src={haymes} alt="invictus" />
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={3}>
                                <Image fluid src={dulux} alt="invictus" />
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={3}>
                                <Image fluid src={taubmans} alt="invictus" />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}
