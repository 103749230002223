import React, { Component } from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import Slider from "react-slick";
import avatar from "../assets/images/user1.svg";
import avatar2 from "../assets/images/user2.svg";

import star from "../assets/images/star.svg";


export default class componentName extends Component {
    render() {
        const settings = {
            fade: true,
            arrows: false,
            infinite: true,
            speed: 800,
            autoplay: true,
            autoplaySpeed: 4000,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true
        };
        return (
            <React.Fragment>
                <div className="say" id="Testimonials">
                    <Container>
                        <Row className="justify-content-center">
                            <Col sm={12}>
                                <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                                    <h2 className="title-say">Clients Say</h2>
                                </div>
                            </Col>
                            <Col sm={12}>
                                <Slider {...settings}>
                                    <div className="slide-say">
                                        <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                                            <Image fluid className="image-say" src={avatar2} />

                                            <p className="name-say"><span>Rade L. <br /> </span>founder of Betacon PTY LTD</p>
                                            <Image fluid className="star-say" src={star} />
                                            <div className="text-position-say">
                                                <p className="text-say">As a trustworthy construction company in our business model, we collaborate just with highly-skilled professionals, and Invictus Painters are one of them. I have found Invictus Painters to be a very valuable partner, and a well-run company driven by quality.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="slide-say">
                                        <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                                            <Image fluid className="image-say" src={avatar} />
                                            <p className="name-say"><span>Ned M. <br /> </span>house-owner</p>
                                            <Image fluid className="star-say" src={star} />
                                            <div className="text-position-say">
                                                <p className="text-say">It was a great experience from the minute I called to make the appointment. Invictus Painters are professional and kind. Painters were on time, and they cleaned after the job was done. I will give them a call in the future.</p>
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}
