import React, { Component } from 'react'
import Navigation from '../components/Navigation'
import Intro from '../components/Intro'
import AboutUs from '../components/AboutUs'
import Services from '../components/Services'
import Say from '../components/Say'
import Work from '../components/Work'
import Prices from '../components/Prices'
import Contact from '../components/Contact'
import Footer from '../components/Footer'
import AOS from 'aos';
import '../assets/css/aos.css';

export default class componentName extends Component {
    
    componentDidMount() {
        AOS.init({
            duration: 800
        })
    }
    render() {
        return (
            <React.Fragment>
                <Navigation />
                <Intro />
                <AboutUs />
                <Services />
                <Say />
                <Work />
                <Prices />
                <Contact />
                <Footer />
            </React.Fragment>
        )
    }
}
